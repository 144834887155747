import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Auth {
  username: string;
  fullName: string;
  email: string;
  token: string;
}

interface AuthState {
  auth?: Auth;
}

function getInitialState(): AuthState {
  const username = localStorage.getItem("auth_username");
  const email = localStorage.getItem("auth_email");
  const fullName = localStorage.getItem("auth_full_name");
  const token = localStorage.getItem("auth_token");
  if (username && email && token && fullName) {
    return { auth: { username, email, fullName, token } };
  }
  return { auth: undefined };
}

const initialState = getInitialState();

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<Auth>) => {
      state.auth = action.payload;
      localStorage.setItem("auth_username", state.auth.username);
      localStorage.setItem("auth_full_name", state.auth.fullName);
      localStorage.setItem("auth_email", state.auth.email);
      localStorage.setItem("auth_token", state.auth.token);
    },
    logout: (state, action: PayloadAction<void>) => {
      state.auth = undefined;
      localStorage.removeItem("auth_username");
      localStorage.removeItem("auth_full_name");
      localStorage.removeItem("auth_email");
      localStorage.removeItem("auth_token");
    },
  },
});

export const { setAuth, logout } = authSlice.actions;

export default authSlice.reducer;
