import React, { useEffect, useState } from "react";
import { Flex, Text } from "@mantine/core";
import { RingProgress } from "@mantine/core";
import { useInterval } from "@mantine/hooks";

const StateIndicator: React.FC<{ status: string | undefined }> = ({
  status,
}) => {
  const [progress, setProgress] = useState(0);
  // 30fps == each 33ms
  const statusMessage = status ? status : "unknown";
  const interval = useInterval(
    () => setProgress((progress) => progress + 0.025),
    33,
  );

  useEffect(() => {
    interval.start();
    return () => interval.stop();
  }, []);

  return (
    <Flex
      direction="row"
      align="center"
      bg="black"
      p="8px"
      style={{
        borderRadius: "8px",
        fontFamily:
          "JetBrains Mono, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace",
      }}
    >
      <RingProgress
        size={36}
        thickness={4}
        sections={[
          { value: 60 * progress, color: "#ebebeb" },
          { value: 40, color: "cyan" },
          { value: 60 * (1 - progress), color: "#ebebeb" },
        ]}
      />
      <div>
        <Text mx="4px" size="xs" c="white">
          Document is being generated
        </Text>
        <Text ml="4px" size="xs" c="white">
          Status: <span style={{ color: "#9a9a9a" }}>{statusMessage}</span>
        </Text>
      </div>
    </Flex>
  );
};

export default StateIndicator;
