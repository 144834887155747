import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  directlyGenerateContract,
  finetunePrompt,
  getModels,
} from "../utils/mockApi";
import {initSections, setData, setOutline, setPromptQuestions, setShownPrompt} from "../redux/diff";
import {
  Alert,
  Button,
  LoadingOverlay,
  NumberInput,
  Select,
  Textarea,
  Text,
  Title,
} from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";

const DEFAULT_TITLE = "Rental contract";
const DEFAULT_PROMPT =
  "Rental contract for a Skoda 120 car between ABC s.r.o. and Matus Zilinec. The car is rented for business purposes for a fixed period of 12 months between 1.1.2024 and 31.12.2024. The rent is 10 000 czk monthly.";
const IS_FINETUNE_PROMPT_ENABLED = false;

const ActionNew: React.FC = () => {
  const [contractTitle, setContractTitle] = useState(DEFAULT_TITLE);
  const [prompt, setPrompt] = useState(DEFAULT_PROMPT);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.diff.data);

  const [variants, setVariants] = useState<string | number | undefined>();
  const [model, setModel] = useState<string | null>(null);
  const [models, setModels] = useState([]);

  const [modelError, setModelError] = useState(false);

  useEffect(() => {
    setLoading(true);
    getModels(data.language).then((resp) =>
      resp.json().then((data) => {
        setModels(data);
        setLoading(false);
      }),
    );
  }, []);

  const confirmPrompt = () => {
    if (model === null) {
      setModelError(true);
      return;
    }
    setLoading(true);
    const nVariants = Number.isInteger(variants) ? Number(variants) : 1;
    // TODO: move this and duplicated code in FinetunePrompt somewhere else
    if (IS_FINETUNE_PROMPT_ENABLED) {
      finetunePrompt(data.id, contractTitle, prompt, model, nVariants).then(
          async (resp) => {
            const json = await resp.json();
            dispatch(setData(json));
          },
      );
    } else {
      directlyGenerateContract(data.id, contractTitle, prompt, model, nVariants).then(
          async (resp) => {
            const json = await resp.json();
            dispatch(setData(json));
          },
      );
    }
  };

  useEffect(() => {
    if (model !== null && modelError) {
      setModelError(false);
    }
  }, [model]);

  return (
    <div>
      {data.state === "failed" && (
        <Alert
          variant="light"
          color="red"
          title="Writing failed"
          icon={<IconInfoCircle />}
          mb="16px"
        >
          The contract was not generated due to a server error. Please try
          again.
        </Alert>
      )}
      <Title order={3} mb="8px">
        Create contract
      </Title>
      <Text c="#3e3e3e" span>
        What kind of contract would you like to create? Please be specific.
      </Text>
      <Textarea
        label="Title (not used yet)"
        description="What should the contract be called?"
        minRows={2}
        autosize
        value={contractTitle}
        onChange={(event) => setContractTitle(event.currentTarget.value)}
        mt="16px"
      />
      <Textarea
        label="Prompt"
        description="Describe what this contract should contain."
        minRows={3}
        autosize
        value={prompt}
        onChange={(event) => setPrompt(event.currentTarget.value)}
        mt="12px"
      />
      <Select
        label="Language model"
        placeholder="Which language model should be used?"
        data={models}
        value={model}
        onChange={setModel}
        mt="12px"
        error={modelError && "Please select a model"}
      />
      {
        <NumberInput
          label="Number of variants"
          placeholder="How many contract variants should be generated?"
          value={variants}
          onChange={setVariants}
          clampBehavior="strict"
          min={1}
          max={3}
        />
      }
      <Button variant="gradient" mt="12px" onClick={() => confirmPrompt()}>
        {IS_FINETUNE_PROMPT_ENABLED ? "Next" : "Generate"}
      </Button>
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
    </div>
  );
};

export default ActionNew;
