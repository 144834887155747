import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface Section {
  id: number;
  name: string;
  text: string;
  edited: boolean;
}

export interface VariantsDict {
  [key: number]: Section[];
}

export interface Data {
  id: number;
  name: string;
  contract_title: string;
  model: string;
  state:
    | "new"
    | "finetune_prompt"
    | "outline"
    | "edit"
    | "generating"
    | "failed"
    | undefined;
  generating_status: string | undefined;
  prompt: string;
  llm_questions: string[];
  namedEntities: string[];
  // sections: string[];
  // contents: string[];
  text: string;
  wholeText: string;
  language: string;
  createdAt: string;
  outline: string[];
  variants: VariantsDict;
}

export interface SectionBody {
  variantIx: number;
  sectionId: number;
  content: string;
}

export interface DiffState {
  isShowingDiff: boolean;
  diffDocument: string;
  data: Data;
}

const initialState: DiffState = {
  isShowingDiff: false,
  diffDocument: "",
  data: {
    id: -1,
    name: "",
    contract_title: "",
    model: "",
    state: undefined,
    generating_status: undefined,
    prompt: "",
    llm_questions: [],
    namedEntities: [],
    // sections: [],
    // contents: [],
    text: "",
    wholeText: "",
    language: "",
    createdAt: "",
    outline: [],
    variants: {},
  },
};

export const diffSlice = createSlice({
  name: "diff",
  initialState,
  reducers: {
    toggle: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      return { ...state, isShowingDiff: !state.isShowingDiff }; // TODO: move this under editor
    },
    set: (state, action: PayloadAction<boolean>) => {
      return { ...state, isShowingDiff: action.payload };
    },
    setDiffDocument: (state, action: PayloadAction<string>) => {
      return { ...state, diffDocument: action.payload };
    },
    setData: (state, action: PayloadAction<Data>) => {
      return { ...state, data: action.payload };
    },
    setSectionContent: (state, action: PayloadAction<SectionBody>) => {
      const variantIx = action.payload.variantIx;
      const sectionId = action.payload.sectionId;
      if (sectionId !== -1) {
        state.data.variants[variantIx].forEach((value, index) => {
          if (value.id === sectionId) value.text = action.payload.content;
        });
      } else {
        state.data.wholeText = action.payload.content;
      }
    },
    initSections: (state, action: PayloadAction<void>) => {
      const data = { ...state.data };
      // TODO: do we need this anymore??
      // data.contents = state.data.sections.map((c) => "");
      data.state = "generating";
      return { ...state, data };
    },
    setOutline: (state, action: PayloadAction<string[]>) => {
      const data = { ...state.data };
      data.outline = action.payload;
      data.state = "outline";
      return { ...state, data };
    },
    setShownPrompt: (state, action: PayloadAction<string>) => {
      const data = { ...state.data };
      data.prompt = action.payload;
      return { ...state, data };
    },
    setPromptQuestions: (state, action: PayloadAction<string[]>) => {
      const data = { ...state.data };
      data.llm_questions = action.payload;
      data.state = "finetune_prompt";
      return { ...state, data };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggle,
  set,
  setDiffDocument,
  setData,
  setSectionContent,
  setOutline,
  setPromptQuestions,
  setShownPrompt,
  initSections,
} = diffSlice.actions;

export default diffSlice.reducer;
