import styled from "styled-components";
import { Group, Anchor, Text } from "@mantine/core";

const Footer = styled.footer`
  margin-top: auto;
  border-top: 1px solid #e9ecef; //light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5));
  font-family:
    JetBrains Mono,
    ui-monospace,
    SFMono-Regular,
    Menlo,
    Monaco,
    Consolas,
    Liberation Mono,
    Courier New,
    monospace;
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--mantine-spacing-md) var(--mantine-spacing-md);

  @media (max-width: $mantine-breakpoint-sm) {
    flex-direction: column;
  }
`;

const links = [
  { link: "#", label: "Contact" },
  { link: "#", label: "Privacy" },
  { link: "#", label: "Terms" },
  { link: "#", label: "Careers" },
];

const AppFooter: React.FC = () => {
  return (
    <Footer>
      <Inner>
        <Text size="sm" c="#2a2a2a">
          (c) Pactus AI 2024
        </Text>
        <Group>
          {links.map((link) => (
            <Anchor
              c="dimmed"
              key={link.label}
              href={link.link}
              lh={1}
              onClick={(event) => event.preventDefault()}
              size="sm"
            >
              {link.label}
            </Anchor>
          ))}
        </Group>
      </Inner>
    </Footer>
  );
};

export default AppFooter;
