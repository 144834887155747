import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getContract } from "../utils/mockApi";
import { setData } from "../redux/diff";
import { Card, Flex, LoadingOverlay, Text } from "@mantine/core";
import styled from "styled-components";
import Typewriter from "../components/Typewriter";
import StateIndicator from "../components/StateIndicator";
import { Box } from "@mui/material";

const ChatContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 800px) {
    padding: 16px;
  }
  padding: 28px;
  background: #fdfdfd;
`;

const TextContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  padding: 4px;
`;

const GeneratingView: React.FC<{}> = ({}) => {
  const data = useAppSelector((state) => state.diff.data);
  const [genText, setGenText] = useState<string | null>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data.id == -1) return;
    const interval = setInterval(() => {
      // TODO: make an endpoint that returns only state, or use websockets
      getContract(data.id).then((resp) =>
        resp.json().then((json) => {
          if (json.state === "edit" || json.state === "generating") {
            dispatch(setData(json));
            console.log(`Setting ${json.wholeText}`);
            setGenText(json.wholeText);
          }
        }),
      );
    }, 1000);
    return () => clearInterval(interval);
  }, [data.id]);

  // Rental contract for a Skoda 120 car between ABC s.r.o. and Matus Zilinec. The car is rented for business purposes for a fixed period of 12 months between 1.1.2024 and 31.12.2024. The rent is 10 000 czk monthly.

  return (
    <ChatContainer>
      {data && data.state === "generating" && (
        <div>
          <StateIndicator status={data.generating_status} />
          <Box mb="12px" />

          {data.wholeText !== null && (
            <Card
              withBorder
              shadow="md"
              style={{
                // width: "33%",
                maxWidth: "900px",
                marginLeft: "auto",
                marginRight: "auto",
                padding: "16px",
                border: "1px solid #e0e0e0",
              }}
            >
              <Text style={{ whiteSpace: "pre-wrap", fontFamily: "Georgia" }}>
                {data.wholeText}
              </Text>
            </Card>
          )}
          {data.wholeText === null && data.variants && (
            <Flex direction="row">
              {Object.keys(data.variants).map((variantId) => (
                <Card
                  withBorder
                  shadow="md"
                  style={{
                    // width: "33%",
                    flexGrow: 1,
                    flexShrink: 1,
                    flexBasis: 0,
                    // maxWidth: "900px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    padding: "16px",
                    border: "1px solid #e0e0e0",
                  }}
                >
                  <Text style={{ color: "#555" }}>
                    Variant {Number(variantId) + 1}
                  </Text>
                  <TextContainer key={variantId}>
                    {data.variants[variantId as any].map((section, ix) => (
                      <div>
                        {data.variants[variantId as any].length > 1 && (
                          <>
                            <b>Section {ix + 1}</b>
                            <br />
                          </>
                        )}
                        <Typewriter text={section.text} />
                      </div>
                    ))}
                  </TextContainer>
                </Card>
              ))}
            </Flex>
          )}
        </div>
      )}
      {!data ||
        (data.state == undefined && (
          <LoadingOverlay
            visible={true}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          />
        ))}
    </ChatContainer>
  );
};

export default GeneratingView;
