import styled from "styled-components";
import React, { useState } from "react";
import {
  Autocomplete,
  Burger,
  Group,
  Menu,
  Title,
  Avatar,
  UnstyledButton,
  Text,
} from "@mantine/core";
import {
  IconChevronDown,
  IconHeart,
  IconSettings,
  IconLogout,
  IconMessage,
} from "@tabler/icons-react";
import "@mantine/core/styles.css";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { logout } from "../redux/auth";
import { useNavigate } from "react-router-dom";

const Header = styled.header`
  height: 56px;
  // margin-bottom: rem(120px);
  background-color: white; #f8f9fa;
  border-bottom: 1px solid #dee2e6; //light-dark(#dee2e6, #ced4da);
  font-family: DM Sans, -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
  padding-left: 1px;
  padding-right: 10px;
`;

const Inner = styled.div`
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Link = styled.a`
  display: block;
  line-height: 1;
  padding: 8px 12px;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #495057; //light-dark(#495057, #343a40);
  font-size: 0.875rem;

  :hover {
    background-color: #f8f9fa; //light-dark(#f8f9fa, #25262b);
  }
`;

const links = [
  { link: "/about", label: "Features" },
  { link: "/pricing", label: "Pricing" },
  { link: "/learn", label: "Learn" },
  { link: "/community", label: "Community" },
];

const AppHeader: React.FC = () => {
  // return (<Skeleton variant="rectangular" width='100%' height={48} />)
  const opened = false;
  const toggle = () => {};

  const [menuOpened, setMenuOpened] = useState(false);

  const authState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  if (authState.auth == null) {
    return <></>;
  }

  const user = {
    image: "",
    name: authState.auth?.fullName,
  };

  const doLogout = () => {
    dispatch(logout());
  };

  return (
    <Header>
      <Inner>
        <Group>
          <Burger opened={opened} onClick={toggle} size="sm" hiddenFrom="sm" />
          {/*<MantineLogo size={28} />*/}

          <span style={{ marginLeft: 16 }}>Pactus AI</span>
          <Link href="/">Home</Link>
        </Group>
        {/*<Group>*/}
        {/*  <Group ml={50} gap={5} visibleFrom="sm">*/}
        {/*      {links.map((item) => (<Link>{item.label}</Link>))}*/}
        {/*  </Group>*/}
        {/*</Group>*/}
        <Menu
          width={260}
          position="bottom-end"
          transitionProps={{ transition: "pop-top-right" }}
          onClose={() => setMenuOpened(false)}
          onOpen={() => setMenuOpened(true)}
          withinPortal
        >
          <Menu.Target>
            <UnstyledButton>
              <Group gap={7}>
                <Avatar
                  src={user.image}
                  alt={user.name}
                  radius="xl"
                  size={20}
                />
                <Text fw={500} size="sm" lh={1} mr={3}>
                  {user.name}
                </Text>
                <IconChevronDown
                  style={{ width: 12, height: 12 }}
                  stroke={1.5}
                />
              </Group>
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              leftSection={
                <IconHeart
                  style={{ width: 16, height: 16 }}
                  color="red"
                  stroke={1.5}
                />
              }
            >
              Favorite contracts
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconMessage
                  style={{ width: 16, height: 16 }}
                  color="blue"
                  stroke={1.5}
                />
              }
            >
              Send feedback
            </Menu.Item>
            <Menu.Divider />
            <Menu.Label>Settings</Menu.Label>
            <Menu.Item
              leftSection={
                <IconSettings style={{ width: 16, height: 16 }} stroke={1.5} />
              }
            >
              Account settings
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconLogout style={{ width: 16, height: 16 }} stroke={1.5} />
              }
              onClick={doLogout}
            >
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Inner>
    </Header>
  );
};

export default AppHeader;
