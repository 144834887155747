import React, { useEffect, useState } from "react";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, LoadingOverlay, Switch, Text, TextInput } from "@mantine/core";
import { IconGripVertical, IconPlus, IconX } from "@tabler/icons-react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { regenerateDocument, setSectionNames } from "../utils/mockApi";
import { initSections, setOutline } from "../redux/diff";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

const OutlineItem: React.FC<{
  item: string;
  index: number;
  updateSection: (index: number, name: string) => void;
  deleteSection: (index: number) => void;
}> = ({ item, index, updateSection, deleteSection }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <TextInput
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      leftSectionPointerEvents="none"
      leftSection={
        <>
          <IconGripVertical style={{ height: 16, width: 16, cursor: "grab" }} />
          <span>{index + 1}.</span>
        </>
      }
      rightSection={
        <IconX
          style={{ width: 16, height: 16 }}
          onClick={() => deleteSection(index)}
        />
      }
      value={item}
      onChange={(event) => updateSection(index, event.currentTarget.value)}
      mb="8px"
    />
  );
};

const ActionOutline: React.FC = () => {
  const data = useAppSelector((state) => state.diff.data);
  const dispatch = useAppDispatch();

  const [sections, setSections] = useState<string[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [byParts, setByParts] = useState(true);

  useEffect(() => {
    setSections(data.outline);
  }, []); // once

  const deleteSection = (index: number) => {
    setSections(sections.filter((v, i) => i !== index));
  };

  const updateSection = (index: number, name: string) => {
    setSections(
      sections.map((value, i) => {
        if (i == index) return name;
        return value;
      }),
    );
  };

  const appendSection = () => {
    setSections([...sections, ""]);
  };

  const confirmOutline = () => {
    setLoading(true);
    // send new outline to server
    setSectionNames(data.id, sections).then((resp) => {
      // wait for success and then:
      dispatch(setOutline(sections));
      // now ask backend to generate section contents
      regenerateDocument(data.id, byParts).then((resp) => {
        // set document state to generating
        dispatch(initSections());
      });
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setSections((items) => {
        const oldIndex = sections.indexOf(active.id);
        const newIndex = sections.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <div>
      <Text size="md" mb="10px">
        An outline has been generated for you. Feel free to rename or reorder
        sections by drag-and-drop.
      </Text>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={sections}
          strategy={verticalListSortingStrategy}
        >
          {sections.map((item, index) => (
            <OutlineItem
              key={item}
              item={item}
              index={index}
              updateSection={updateSection}
              deleteSection={deleteSection}
            />
          ))}
        </SortableContext>
      </DndContext>
      <div>
        <Switch
          my="12px"
          mx="6px"
          label="Generate by parts?"
          description={byParts ? "yes" : "no"}
          checked={byParts}
          onChange={(e) => setByParts(e.currentTarget.checked)}
        />
      </div>

      <Button
        variant="outline"
        leftSection={<IconPlus />}
        onClick={() => appendSection()}
      >
        Add section
      </Button>
      <Button ml="8px" variant="gradient" onClick={() => confirmOutline()}>
        Create contract
      </Button>
      {(data.state === "generating" || isLoading) && (
        <LoadingOverlay
          visible={true}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      )}
    </div>
  );
};

export default ActionOutline;
