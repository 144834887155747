import React, { useCallback, useEffect, useMemo, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import {
  Table,
  Text,
  Button,
  Title,
  useMantineTheme,
  getGradient,
  LoadingOverlay,
} from "@mantine/core";

import { getContractList } from "../utils/mockApi";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import CreateContractDialog from "./CreateContractDialog";
import { IconScript, IconTrashX } from "@tabler/icons-react";
import AppContainer from "../AppContainer";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { logout } from "../redux/auth";
import DeleteContractDialog from "./DeleteContractDialog";

const Tr = styled(Table.Tr)`
  &:hover {
    background-color: #f8f9fa;
    cursor: pointer;
  }

  font-family: DM Sans;
`;

const ContractList: React.FC = ({}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authToken = useAppSelector((state) => state.auth.auth?.token) ?? "";

  const [isCreatingModal, setCreatingModal] = useState(false);
  const [deletingId, setDeletingId] = useState(undefined);

  const [contracts, setContracts] = useState([]);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!shouldRefresh) return;
    setLoading(true);
    getContractList(authToken).then(async (result) => {
      if (result.status == 403) {
        dispatch(logout());
        navigate("/login");
      } else if (result.status == 200) {
        setContracts(await result.json());
      }
      setLoading(false);
      setShouldRefresh(false);
    });
  }, [shouldRefresh]);

  const navigateToItem = useCallback((index: number) => {
    navigate("/app", { state: { id: index } });
  }, []);

  return (
    <AppContainer>
      <CreateContractDialog
        isOpen={isCreatingModal}
        setIsOpen={setCreatingModal}
      />
      <DeleteContractDialog
        id={deletingId}
        isOpen={deletingId !== undefined}
        doClose={() => {
          setDeletingId(undefined);
          setShouldRefresh(true);
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          borderBottom: "1px solid #a0a0a0",
        }}
      >
        <Title
          p="24px"
          order={2}
          variant="gradient"
          fw="500"
          style={(theme) => ({
            background: getGradient(
              { deg: 45, from: "blue", to: "cyan" },
              theme,
            ),
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            // color: "blue",
            fontFamily: "DM Sans",
          })}
        >
          My contracts
        </Title>
        <Button
          leftSection={<AddIcon />}
          onClick={() => {
            setCreatingModal(true);
          }}
          aria-label="add"
          style={{ alignSelf: "end", marginRight: "32px", top: "18px" }}
        >
          Create New
        </Button>
      </div>
      <Table maw="1150px" mx="auto" mb="48px">
        <Table.Thead>
          <Table.Th />
          <Table.Th>ID</Table.Th>
          <Table.Th>Name</Table.Th>
          <Table.Th>Language</Table.Th>
          <Table.Th>Model</Table.Th>
          <Table.Th>Created at</Table.Th>
          <Table.Th></Table.Th>
        </Table.Thead>
        <Table.Tbody>
          {!isLoading &&
            contracts &&
            contracts.map((value: any) => (
              <Tr
                key={value.id}
                onClick={() => {
                  navigateToItem(value.id);
                }}
              >
                <Table.Td>
                  <IconScript style={{ width: 18, height: 18 }} />
                </Table.Td>
                <Table.Td>
                  <Text>{value.id}</Text>
                </Table.Td>
                <Table.Td>
                  <Text>{value.name}</Text>
                </Table.Td>
                <Table.Td>
                  <Text>{value.language}</Text>
                </Table.Td>
                <Table.Td>
                  <Text>{value.model}</Text>
                </Table.Td>
                <Table.Td>
                  {new Date(value.createdAt).toLocaleString()}
                </Table.Td>
                <Table.Td>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeletingId(value.id);
                    }}
                  >
                    <IconTrashX strokeWidth={1.5} color="#6e6e6e" />
                  </div>
                </Table.Td>
              </Tr>
            ))}
        </Table.Tbody>
      </Table>
      {isLoading && (
        <LoadingOverlay
          visible={true}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      )}
    </AppContainer>
  );
};

export default ContractList;
