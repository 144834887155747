import styled from "styled-components";
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import React from "react";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default function AppContainer({ children }: { children: any }) {
  const authState = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  if (authState.auth == null) {
    navigate("/login");
    return <></>;
  }

  return (
    <>
      <AppHeader />
      {children}
      <AppFooter />
    </>
  );
}
