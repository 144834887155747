import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { generateOutline, regenerateDocument } from "../utils/mockApi";
import { initSections, setOutline } from "../redux/diff";
import { Button, LoadingOverlay, Text, Textarea } from "@mantine/core";

const ActionFinetune: React.FC = () => {
  const data = useAppSelector((state) => state.diff.data);
  const dispatch = useAppDispatch();
  const originalPrompt = data.prompt;

  const [prompt, setPrompt] = useState(originalPrompt);
  const [isLoading, setLoading] = useState(false);

  // TODO: move this and copied code from NewContract into a separate class
  const willGenerateOutline = data.model.startsWith("gpt");

  const confirmPrompt = () => {
    setLoading(true);
    if (willGenerateOutline) {
      // for gpt, we need an outline first
      generateOutline(data.id, prompt).then(async (resp) => {
        const json = await resp.json();
        dispatch(setOutline(json.sections));
      });
    } else {
      // for custom LLMs, we don't use an outline
      regenerateDocument(data.id, false).then((resp) => {
        // set document state to generating
        dispatch(initSections());
      });
    }
  };

  return (
    <div>
      <div
        style={{
          borderBottom: "1px solid #eee",
          paddingBottom: "10px",
          marginBottom: "10px",
        }}
      >
        <span>Your original prompt is:</span>
        <Textarea
          minRows={3}
          autosize
          value={originalPrompt}
          contentEditable={false}
          disabled={true}
          mt="8px"
        />
      </div>
      <span>
        <Text>
          The system suggests that you optionally add the following information
          for a better result:
        </Text>
        <br />
        {data.llm_questions.map((q) => (
          <Text size="sm" mb="4px">
            * {q}
            <br />
          </Text>
        ))}
      </span>
      <Textarea
        minRows={3}
        autosize
        value={prompt}
        onChange={(event) => setPrompt(event.currentTarget.value)}
        mt="8px"
      />
      <Button variant="gradient" mt="8px" onClick={() => confirmPrompt()}>
        {willGenerateOutline ? "Generate outline" : "Generate document"}
      </Button>
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
    </div>
  );
};

export default ActionFinetune;
