import { configureStore } from "@reduxjs/toolkit";
import diffReducer from "./diff";
import authReducer from "./auth";
import editorReducer from "./editor";

export const store = configureStore({
  reducer: {
    diff: diffReducer,
    auth: authReducer,
    editor: editorReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
