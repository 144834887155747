import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface EditorState {
  selection?: string;
}

const initialState: EditorState = {
  selection: undefined,
};

export const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setSelection: (state, action: PayloadAction<string | undefined>) => {
      state.selection = action.payload;
    },
  },
});

export const { setSelection } = editorSlice.actions;
export default editorSlice.reducer;
