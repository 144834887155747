import { useAppSelector } from "../redux/hooks";
import Section from "./Section";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Flex, Kbd, Loader, Paper, Text, Title } from "@mantine/core";
import {
  getDownloadUrl,
  updateContract,
  UpdateSection,
} from "../utils/mockApi";
import ReactTimeAgo from "react-time-ago";
import { Data } from "../redux/diff";

const StyledDocument = styled.div`
  width: 100%; // TODO: with variants?
  //padding: 20px;
  background-color: #fafafa;
`;

const NamedEntities: React.FC = () => {
  const data = useAppSelector((state) => state.diff.data);
  return (
    <Paper p="16px" style={{ maxWidth: "30rem" }} withBorder={true}>
      <Text size="md">Named entities</Text>
      {data.namedEntities &&
        data.namedEntities.map((n) => (
          <Text size="xs" span={true}>
            {n},{" "}
          </Text>
        ))}
    </Paper>
  );
};

const PromptView: React.FC<{ lastSaved: Date; downloadUrl: string }> = ({
  lastSaved,
  downloadUrl,
}) => {
  const prompt = useAppSelector((state) => state.diff.data.prompt);
  return (
    <Paper mb="12px" shadow="sm" p="12px">
      <Flex justify="space-between">
        <div>
          <Text size="sm" fw="500" c="#000000" span>
            Your request
          </Text>
          <Text size="sm" mb="5px" ml="10px" span>
            {prompt}
          </Text>
          <Flex align="baseline">
            {/*<Loader style={{alignSelf: 'center'}} mr="5px" color="cyan" size="xs" type="dots" />*/}
            <Text size="sm" fw="500" span={true} mr="5px">
              Last saved
            </Text>{" "}
            <Text size="sm">
              <ReactTimeAgo
                timeStyle="round"
                date={new Date(lastSaved)}
                locale="en-US"
              />{" "}
              (<Kbd>⌘</Kbd> + <Kbd>S</Kbd> to save manually)
            </Text>
          </Flex>
        </div>
        <a href={downloadUrl} style={{ alignSelf: "center" }}>
          <Button variant="default">Export as DOCX</Button>
        </a>
      </Flex>
    </Paper>
  );
};

const DocumentView: React.FC = () => {
  const data = useAppSelector((state) => state.diff.data);
  const [lastSaved, setLastSaved] = useState<number>(Date.now);

  const saveChanges = () => {
    const newSections: UpdateSection[] = [];
    Object.keys(data.variants).forEach((variantId) => {
      data.variants[variantId as any].forEach((section) => {
        newSections.push({
          id: section.id,
          name: section.name,
          content: section.text,
        });
      });
    });
    updateContract(data.id, data.wholeText, newSections)
      .then(() => {
        setLastSaved(Date.now);
      })
      .catch((e) => {
        console.log("Error saving document");
        console.log(e);
        // TODO show message to user
      });
  };

  const keydownListener = (e: KeyboardEvent) => {
    const isCtrl = navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey;
    if (e.key === "s" && isCtrl) {
      e.preventDefault();
      saveChanges();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", keydownListener);
    const interval = setInterval(() => {
      saveChanges();
    }, 60_000); // 60s
    return () => {
      clearInterval(interval);
      window.removeEventListener("keydown", keydownListener);
    };
  }, [keydownListener]);

  return (
    <StyledDocument>
      <PromptView
        lastSaved={new Date(lastSaved)}
        downloadUrl={getDownloadUrl(data.id)}
      />
      {data.wholeText ? (
        <Section title={data.contract_title} sectionIx={-1} variantIx={-1} />
      ) : (
        <Flex direction="row">
          {Object.keys(data.variants).map((variantId, variantIx) => (
            <div>
              <Text>Variant {variantIx + 1}</Text>
              {data.variants[variantId as any].map((section, sectionIx) => (
                <Section
                  title={section.name}
                  variantIx={variantIx}
                  sectionIx={sectionIx}
                />
              ))}
            </div>
          ))}
        </Flex>
      )}
      <NamedEntities />
    </StyledDocument>
  );
};

export default DocumentView;
