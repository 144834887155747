import { useDisclosure } from "@mantine/hooks";
import {
  Modal,
  Text,
  Button,
  TextInput,
  LoadingOverlay,
  SegmentedControl,
  Select,
} from "@mantine/core";
import { set } from "../redux/diff";
import React, { useEffect, useState } from "react";
import { createContract, getModels } from "../utils/mockApi";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";

interface Props {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const CreateContractDialog: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const authToken = useAppSelector((state) => state.auth.auth?.token) ?? "";

  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState<string | undefined>(undefined);
  const [language, setLanguage] = useState("cs");

  const onCreateContract = () => {
    if (name !== undefined && name !== "") {
      setLoading(true);
      console.log(name);
      createContract(name, language, authToken)
        .then((result) => result.json())
        .then((result) => navigate("/app", { state: { id: result.id } }));
      // TODO: handle errors
    }
  };

  return (
    <Modal
      opened={isOpen}
      onClose={() => setIsOpen(false)}
      title="Create document"
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TextInput
          label="Document name"
          description="How do you want to call this document?"
          placeholder="Office rental contract"
          value={name}
          onChange={(event) => setName(event.currentTarget.value)}
        />
        <Text my="8px" size="sm" fw={500}>
          Contract language
        </Text>
        <SegmentedControl
          value={language}
          onChange={setLanguage}
          data={[
            { label: "English", value: "en" },
            { label: "Czech", value: "cs" },
          ]}
        />
        <Button variant="gradient" mt="16px" onClick={() => onCreateContract()}>
          Create
        </Button>
        <LoadingOverlay
          visible={isLoading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      </div>
    </Modal>
  );
};

export default CreateContractDialog;
