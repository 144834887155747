import React, { useEffect, useState } from "react";

const Typewriter = ({ text }: { text: string }) => {
  const [displayedText, setDisplayedText] = useState("");

  // initial text
  useEffect(() => {
    setDisplayedText(text)
  }, [])

  useEffect(() => {
    if (text?.startsWith(displayedText)) {
      // Calculate the new part of the text that needs to be animated
      const additionalText = text.slice(displayedText.length);

      let index = -1;
      const timer = setInterval(() => {
        setDisplayedText((prev) => prev + additionalText.charAt(index));
        index += 1;
        if (index === additionalText.length) {
          clearInterval(timer);
        }
      }, 5); // Adjust the typing speed here

      // Cleanup on component unmount or when text changes
      return () => clearInterval(timer);
    } else {
      console.log(`D: ${displayedText}`)
      // If for some reason the new text does not start with the displayed text,
      // reset the animation. This might not be necessary depending on your use case.
      setDisplayedText("");
    }
  }, [text, displayedText]); // Depend on both `text` and `displayedText`

  return <span style={{whiteSpace: "pre-wrap"}}>{displayedText}</span>;
};

export default Typewriter