import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  LoadingOverlay,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useCallback, useState } from "react";
import bcrypt from "bcryptjs-react";
import { doLogin } from "../utils/mockApi";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { setAuth } from "../redux/auth";

export default function AuthenticationTitle() {
  // const [username, setUsername] = useState<string|undefined>(undefined)
  // const [password, setPassword] = useState<string|undefined>(undefined)
  const [isLoading, setLoading] = useState(false);

  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  if (auth.auth !== undefined) {
    navigate("/");
  }

  interface Values {
    username?: string;
    password?: string;
  }

  const form = useForm({
    initialValues: {
      username: "",
      password: "",
    },

    validate: {
      // email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      username: (value) => (value ? null : "invalid username"),
      password: (value: string) => (value ? null : "invalid password"),
    },
  });

  async function hashPassword(password: string) {
    // const saltRounds = 10;
    // const salt = await bcrypt.genSalt(saltRounds);
    const salt = "$2a$12$O5GZcFrD/HMfTuugCBYgW.kO.vsW1Gy1yCLqkN9/P53NKGuanLkxu";
    return await bcrypt.hash(password, salt);
  }

  const onSubmit = useCallback(async (values: Values) => {
    if (!values.username || !values.password) {
      return;
    }
    setLoading(true);
    try {
      const hashedPassword = await hashPassword(values.password);
      const resp = await doLogin(values.username, hashedPassword);
      if (resp.status == 200) {
        const data = await resp.json();
        dispatch(setAuth(data));
      }
      // else throw error, or use .catch(() => {})
      // TODO: show error message in any case
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, []);

  return (
    <Container size={420} my={40}>
      <Title ta="center">Welcome back!</Title>
      <Text c="dimmed" size="sm" ta="center" mt={5}>
        Do not have an account yet?{" "}
        <Anchor size="sm" component="button">
          Create account
        </Anchor>
      </Text>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
          <TextInput
            label="Email"
            placeholder="example@example.com"
            {...form.getInputProps("username")}
          />
          <PasswordInput
            label="Password"
            placeholder="Your password"
            mt="md"
            {...form.getInputProps("password")}
          />
          <Group justify="space-between" mt="lg">
            <Checkbox label="Remember me" />
            <Anchor component="button" size="sm">
              Forgot password?
            </Anchor>
          </Group>
          <Button fullWidth mt="xl" type="submit">
            Sign in
          </Button>
        </form>
      </Paper>
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
    </Container>
  );
}
