import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  sendCreateSections,
  setSectionNames,
  regenerateDocument,
  getContract,
  generateOutline,
  finetunePrompt,
} from "../utils/mockApi";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  initSections,
  setData,
  setOutline,
  setPromptQuestions,
  setShownPrompt,
} from "../redux/diff";
import {
  TextInput,
  Button,
  Timeline,
  Text,
  LoadingOverlay,
  Switch,
  SegmentedControl,
  Textarea,
  Card,
} from "@mantine/core";
import {
  IconPlus,
  IconX,
  IconHexagon,
  IconGripVertical,
} from "@tabler/icons-react";

import ActionNew from "../screens/NewContract";
import ActionFinetune from "../screens/FinetunePrompt";
import ActionOutline from "../screens/Outline";
import { formatDistanceToNow } from "date-fns";
import StateIndicator from "./StateIndicator";

const ChatContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 800px) {
    padding: 16px;
  }
  padding: 28px;
  background: #fdfdfd;
`;

const TimelineView: React.FC = () => {
  const data = useAppSelector((state) => state.diff.data);
  const datetime = data.createdAt ? new Date(data.createdAt) : undefined;
  const relativeTime = datetime
    ? formatDistanceToNow(datetime, { addSuffix: true })
    : "";

  return (
    <div style={{ padding: "16px" }}>
      <Timeline active={1} bulletSize={24} lineWidth={2}>
        {/*<Timeline.Item bullet={<IconHexagon size={12} />} title="New branch">*/}
        {/*  <Text c="dimmed" size="sm">You&apos;ve created new branch <Text variant="link" component="span" inherit>fix-notifications</Text> from master</Text>*/}
        {/*  <Text size="xs" mt={4}>2 hours ago</Text>*/}
        {/*</Timeline.Item>*/}
        <Timeline.Item bullet={<IconHexagon size={12} />} title="Version 1">
          <Text c="dimmed" size="sm">
            Document{" "}
            <Text variant="gradient" component="span" inherit>
              {data.name}
            </Text>{" "}
            was created
          </Text>
          <Text size="xs" mt={4}>
            {relativeTime}
            {/*2 hours ago*/}
          </Text>
        </Timeline.Item>
      </Timeline>
    </div>
  );
};

const ChatInterface: React.FC<{ setDocument: Function }> = ({
  setDocument,
}) => {
  const data = useAppSelector((state) => state.diff.data);
  const [genText, setGenText] = useState<string | null>();
  const dispatch = useAppDispatch();

  console.log("Render");

  useEffect(() => {
    if (data.id == -1) return;
    const interval = setInterval(() => {
      // TODO: make an endpoint that returns only state, or use websockets
      getContract(data.id).then((resp) =>
        resp.json().then((json) => {
          if (json.state === "edit" || json.state === "generating") {
            dispatch(setData(json));
            console.log(`Setting ${json.wholeText}`);
            setGenText(json.wholeText);
          }
        }),
      );
    }, 1000);
    return () => clearInterval(interval);
  }, [data.id]);

  return (
    <ChatContainer>
      <Card
        withBorder
        shadow="md"
        style={{
          // width: "33%",
          maxWidth: "480px",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "16px",
        }}
      >
        {(data.state === "new" || data.state === "failed") && <ActionNew />}
        {data.state === "finetune_prompt" && <ActionFinetune />}
        {data.state === "outline" && <ActionOutline />}
      </Card>
      <TimelineView />
    </ChatContainer>
  );
};

export default ChatInterface;
