import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import { GlobalStyles } from "./styles/GlobalStyles";
import "@mantine/core/styles.css";

import { Provider } from "react-redux";
import { store } from "./redux/store";
import ContractList from "./components/ContractList";
import styled from "styled-components";
import { Skeleton } from "@mui/material";
import { createTheme, MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import CreateContractDialog from "./components/CreateContractDialog";
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import Login from "./components/Login";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container as any);

const theme = createTheme({});

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";

TimeAgo.addDefaultLocale(en);

const router = createBrowserRouter([
  {
    path: "/app",
    element: <App />,
  },
  {
    path: "/",
    element: <ContractList />,
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MantineProvider theme={theme}>
        <GlobalStyles />
        <RouterProvider router={router} />
      </MantineProvider>
    </Provider>
  </React.StrictMode>,
);
