import { useDisclosure } from "@mantine/hooks";
import {
  Modal,
  Text,
  Button,
  TextInput,
  LoadingOverlay,
  SegmentedControl,
  Select,
} from "@mantine/core";
import { set } from "../redux/diff";
import React, { useEffect, useState } from "react";
import { createContract, deleteContract, getModels } from "../utils/mockApi";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";

interface Props {
  isOpen: boolean;
  doClose: () => void;
  id: number | undefined;
}

const DeleteContractDialog: React.FC<Props> = ({ isOpen, doClose, id }) => {
  const authToken = useAppSelector((state) => state.auth.auth?.token) ?? "";

  const onDeleteContract = () => {
    if (id !== undefined) {
      deleteContract(id);
      doClose();
      // TODO: handle errors
    }
  };

  return (
    <Modal opened={isOpen} onClose={() => doClose()} title="Delete document">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Text my="8px" size="sm" fw={500}>
          Are you sure you want to delete this contract? This can't be undone.
        </Text>
        <Button variant="gradient" mt="16px" onClick={() => onDeleteContract()}>
          Delete
        </Button>
        <LoadingOverlay
          visible={false}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
      </div>
    </Modal>
  );
};

export default DeleteContractDialog;
