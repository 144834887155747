import React, { useEffect } from "react";
import ChatInterface from "./components/ChatInterface";
import MarkdownEditor from "./components/MarkdownEditor";
import styled from "styled-components";
import { MDXEditorMethods } from "@mdxeditor/editor";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { setDiffDocument, set, setData } from "./redux/diff";
import Section from "./components/Section";
import DocumentView from "./components/DocumentView";
import ContractList from "./components/ContractList";
import { useLocation } from "react-router-dom";
import { getContract } from "./utils/mockApi";
import AppContainer from "./AppContainer";
import GeneratingView from "./screens/GeneratingDocument";
import {LoadingOverlay} from "@mantine/core";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  useEffect(() => {
    getContract(state.id)
      .then(async (resp) => {
        const data = await resp.json();
        dispatch(setData(data as any));
      })
      .catch(() => {
        // TODO: error handling
      });
  }, [state.id]); // TODO: when loaded basically

  // const [document, setDocumentInternal] = React.useState<string>('');
  const editorRef = React.useRef<MDXEditorMethods>(null);

  const data = useAppSelector((state) => state.diff.data);

  const setDocument = (val: string) => {
    // setDocumentInternal(val);
    dispatch(setDiffDocument(val));
    dispatch(set(true));
    // editorRef.current?.setMarkdown(val);
  };

  return (
    <AppContainer>
      {data.state == undefined && (
          <LoadingOverlay
              visible={true}
              zIndex={1000}
              overlayProps={{ radius: "sm", blur: 2 }}
          />
      )}
      {data.state !== undefined && data.state !== "edit" && data.state !== "generating" && (
        <ChatInterface setDocument={setDocument} />
      )}
      {data.state === "generating" && <GeneratingView />}
      {data.state === "edit" && <DocumentView />}
      {/*<MarkdownEditor editorRef={editorRef} document={document} setDocument={setDocument} />*/}
    </AppContainer>
  );
};

export default App;
