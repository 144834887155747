import styled from "styled-components";
import { sendCreateSection, sendRefineSection } from "../utils/mockApi";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CodeMirrorMerge from "react-codemirror-merge";
import CodeMirror, { ReactCodeMirrorRef } from "@uiw/react-codemirror";
import { MatchDecorator, Decoration } from "@codemirror/view";
import { EditorView } from "codemirror";
import { EditorState } from "@codemirror/state";
import { undo, redo } from "@codemirror/commands";
import diff, { setData, setSectionContent } from "../redux/diff";
import { setSelection } from "../redux/editor";
import {
  ActionIcon,
  Button,
  Card,
  Container,
  Dialog,
  Flex,
  Text,
  TextInput,
} from "@mantine/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { StateEffect, StateField } from "@codemirror/state";
import {
  IconAdjustments,
  IconArrowBack,
  IconArrowForward,
} from "@tabler/icons-react";

const EditorTheme = EditorView.theme({
  "&": {
    fontSize: "1rem",
    // color: '#3a3a3a',
    backgroundColor: "#ffffff",
  },
  ".cm-activeLine": {
    backgroundColor: "inherit",
  },
  ".cm-content": {
    // caretColor: "#0e9",
    fontFamily: "Georgia",
  },
  ".cm-line": {
    // backgroundColor: "#ffffff",
    // borderBottom: "1px solid #eeeeee",
    borderBottom: "none",
  },
  ".ner": {
    fontFamily: "monospace",
    fontSize: "0.85rem",
    backgroundColor: "#e3ff00",
  },
  ".heading": {
    fontWeight: "700",
  },
  ".numbering": {
    fontWeight: "500",
    // fontFamily: "sans-serif",
    backgroundColor: "#00f9ff77",
    padding: "2px",
    borderRadius: "3px",
  },
});

const SectionWrapper = styled.div`
  display: block;
  width: 100%;
  background-color: white;
  padding-top: 20px;
  max-width: 72.5rem;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
`;

const StyledBox = styled.div``;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Title = styled.div`
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 500;
`;

const SelectionMenu = styled.div`
  position: fixed;
  z-index: 999;
  left: 10%;
  top: 10%;
  background: #fafafa;
  padding: 16px;
  border: 1px solid black;
  border-radius: 10px;
  max-width: 30rem;
`;

const EditingButtonsFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 4px;
  background-color: #fcfcfc;
  border-radius: 4px;
  border: 1px solid #aaaaaa;
  color: orangered;
`;

const CollapsibleDiv: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setOpen] = useState<boolean>(true);
  const toggleOpen = () => {
    setOpen(!isOpen);
  };
  return (
    <StyledBox>
      <Button
        size="xs"
        variant="subtle"
        rightSection={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={toggleOpen}
      >
        {isOpen ? "Collapse section" : "Expand section"}
      </Button>
      <div
        style={{
          width: "100%",
          marginBottom: "16px",
          overflow: "hidden",
          // doesn't work, see https://blog.openreplay.com/creating-a-collapsible-component-for-react/
          transition: "max-height 0.5s ease-in-out",
          maxHeight: isOpen ? "none" : 0,
        }}
      >
        {/* Your collapsible content here */}
        {children}
      </div>
    </StyledBox>
  );
};

const DiffView: React.FC<{
  diffText: string;
  originalText: string;
  setCurrentText: (value: string) => void;
}> = ({ diffText, originalText, setCurrentText }) => {
  // THIS MUST *NOT* RE-RENDER
  const Original = CodeMirrorMerge.Original;
  const Modified = CodeMirrorMerge.Modified;

  const diffSetup = {
    history: true,
    indentOnInput: false,
    syntaxHighlighting: false,
    bracketMatching: true,
    closeBrackets: false,
    autocompletion: false,
    connect: true,
  };

  const onChange = useCallback((value: string, viewUpdate: any) => {
    setCurrentText(value);
  }, []);

  // i did all this to avoid flickering when you type in the text field
  const original = useMemo(() => {
    return (
      <Original
        basicSetup={diffSetup}
        indentWithTab={false}
        value={originalText} // copy of original content
        onChange={onChange}
        extensions={[EditorView.editable.of(true), EditorView.lineWrapping]}
      />
    );
  }, [originalText]);

  return (
    <CodeMirrorMerge orientation="b-a" gutter={true} revertControls="b-to-a">
      {original}
      <Modified
        basicSetup={diffSetup}
        indentWithTab={false}
        value={diffText}
        // Editable but read-only: cursor will show up, but you can't write
        extensions={[
          EditorView.editable.of(true),
          EditorState.readOnly.of(true),
          EditorView.lineWrapping,
        ]}
      />
    </CodeMirrorMerge>
  );
};

const SelectionDialog: React.FC<{
  selectedText: string;
  clearSelection: () => void;
  userRequest: string;
  setUserRequest: (req: string) => void;
  onSelectionRewriteRequest: () => void;
}> = ({
  selectedText,
  clearSelection,
  userRequest,
  setUserRequest,
  onSelectionRewriteRequest,
}) => {
  return (
    <Dialog
      opened={true}
      withCloseButton
      onClose={() => clearSelection()}
      size="lg"
      radius="md"
    >
      <div style={{ marginBottom: "10px" }}>
        <Text size="md" fw={500}>
          Selection:
        </Text>
        <Text size="sm">{selectedText}</Text>
      </div>
      <TextInput
        value={userRequest}
        description="What would you like to have changed?"
        placeholder="e.g. change to uppercase"
        onChange={(e) => {
          setUserRequest(e.target.value);
        }}
      />
      <Button
        disabled={userRequest.length <= 0}
        variant="outline"
        mt="10px"
        onClick={onSelectionRewriteRequest}
      >
        Send
      </Button>
    </Dialog>
  );
};

const Toolbar: React.FC<{ onUndo: () => void; onRedo: () => void }> = ({
  onUndo,
  onRedo,
}) => {
  return (
    <Flex w="100%" bg="#f3f3f3">
      <ActionIcon variant="filled" aria-label="undo" mr="5px" onClick={onUndo}>
        <IconArrowBack style={{ width: "70%", height: "70%" }} stroke={1.5} />
      </ActionIcon>
      <ActionIcon variant="filled" aria-label="redo" onClick={onRedo}>
        <IconArrowForward
          style={{ width: "70%", height: "70%" }}
          stroke={1.5}
        />
      </ActionIcon>
    </Flex>
  );
};

const Section: React.FC<{
  title: string;
  variantIx: number;
  sectionIx: number;
}> = ({ title, variantIx, sectionIx }) => {
  const codeMirrorRef = useRef<ReactCodeMirrorRef>({});

  const dispatch = useAppDispatch();
  const content = useAppSelector((state) => {
    return variantIx === -1
      ? state.diff.data.wholeText
      : state.diff.data.variants[variantIx][sectionIx].text ?? "";
  });
  const sectionId = useAppSelector((state) =>
    sectionIx === -1 ? -1 : state.diff.data.variants[variantIx][sectionIx].id,
  );
  const docId = useAppSelector((state) => state.diff.data.id);

  // useEffect(() => {
  //   if (codeMirrorRef.current.editor)
  //   codeMirrorRef.current.editor.textContent = content;
  //   // (codeMirrorRef.current as any).text = content; // TODO: this is invalid for more reasons
  // }, [content]);

  const [diff, setDiff] = useState<string>("");
  const [diffEditedText, setDiffEditedText] = useState<string>("");
  const [selectedText, setSelectedText] = useState<string>("");

  const diffView = useMemo(() => {
    return (
      <DiffView
        originalText={content}
        diffText={diff}
        setCurrentText={setDiffEditedText}
      />
    );
  }, [content, diff]);

  const doGenerate = async () => {
    console.log("--- NOT IMPLEMENTED ---");
    return;

    const response = await sendCreateSection(docId, "", -1);
    const json = await response.json();
    console.log(`Received:`);
    console.log(json);
    // instead of this, i'll set the diff!
    // dispatch(setSectionContent(payload))
    setDiff(json.section_content);
    setDiffEditedText(content);
  };

  const updateSectionFromDiff = () => {
    // TODO: check this
    const payload = {
      variantIx,
      sectionId,
      content: diffEditedText,
    };
    dispatch(setSectionContent(payload));
    setDiff("");
  };

  const onEditViewChange = useCallback(
    (value: string, viewUpdate: any) => {
      const payload = {
        variantIx,
        sectionId,
        content: value,
      };
      console.log("Change to");
      console.log(value);
      dispatch(setSectionContent(payload));
    },
    [dispatch],
  );

  const [userRequest, setUserRequest] = useState<string>("");

  const onSelectionRewriteRequest = () => {
    console.log("--- NOT IMPLEMENTED ---");
    return;

    sendRefineSection(-1, content, selectedText, userRequest).then(
      async (resp) => {
        const text = ((await resp.json()) as any)["text"];
        setDiff(text);
        setDiffEditedText(content);
      },
    );
    setSelectedText("");
    setUserRequest("");
  };

  const clearSelection = () => {
    setSelectedText(""); // TODO: also clear internal state
  };

  const highlight_decoration = Decoration.mark({
    // attributes: {style: "background-color: red"}
    class: "ner",
  });

  const heading_decoration = Decoration.mark({
    // attributes: {style: "background-color: red"}
    class: "heading",
  });

  const numbering_decoration = Decoration.mark({
    // attributes: {style: "background-color: red"}
    class: "numbering",
  });

  const highlight_effect = StateEffect.define<any>();

  // define a new field that will be attached to your view state as an extension, update will be called at each editor's change
  const highlight_extension = StateField.define({
    create() {
      return Decoration.none;
    },
    update(value: any, transaction: any) {
      value = value.map(transaction.changes);
      const regex = new RegExp("\\[[^\\]]+\\]", "dg"); // /\[[^]]\]/g;
      const headingRegex = new RegExp(
        "^(Article|Článek|Čl.)? ?[IVX]+[.:]( |$).*",
        "d",
      );
      const numberingRegex = new RegExp("^[0-9.]+", "d");
      var offset = 0;
      const effects: any[] = [];

      const processText = (text: any) => {
        for (let j in text) {
          const line = text[j];
          var matches;
          while ((matches = regex.exec(line)) != null) {
            const indices = (matches as any)?.indices;
            if (indices) {
              for (const k in indices) {
                if (indices[k]) {
                  const [start, end] = indices[k];
                  effects.push(
                    highlight_decoration.range(offset + start, offset + end),
                  );
                }
              }
            }
          }

          const matches1 = headingRegex.exec(line);
          const indices1 = (matches1 as any)?.indices;
          if (indices1) {
            for (const k in indices1) {
              if (indices1[k]) {
                const [start, end] = indices1[k];
                effects.push(
                  heading_decoration.range(offset + start, offset + end),
                );
              }
            }
          }
          const matches2 = numberingRegex.exec(line);
          const indices2 = (matches2 as any)?.indices;
          if (indices2) {
            for (const k in indices2) {
              if (indices2[k]) {
                const [start, end] = indices2[k];
                effects.push(
                  numbering_decoration.range(offset + start, offset + end),
                );
              }
            }
          }
          offset += line.length + 1;
        }
      };

      for (let i in transaction._doc.children) {
        const text = transaction._doc.children[i].text;
        processText(text);
      }
      // sometimes it's one sometimes the other
      if (transaction._doc.text) {
        processText(transaction._doc.text);
      }
      value = value.update({ filter: (from: number, to: number) => false }); // remove old effects
      value = value.update({ add: effects, sort: true });

      // do we need this even?
      // const effect = highlight_effect.of([highlight_decoration.range(0, 10)])
      // TODO: change only the modified part of text
      // TODO: to remove:::
      // value = value.update({filter: (from: number, to: number) => to <= 0 || from >= 10})

      return value;
    },
    provide: (f: any) => EditorView.decorations.from(f),
  });

  return (
    <Card
      withBorder
      shadow="sm"
      style={{
        // maxWidth: "480px",
        // padding: "16px",
        // display: block;
        // width: 100%;
        // background-color: white;
        // padding-top: 20px;
        maxWidth: "72.5rem",
        paddingLeft: 16,
        paddingRight: 16,
        marginBottom: 16,
        marginLeft: "auto",
        marginRight: "auto",
        // border: 1px solid #e0e0e0;
        // border-radius: 8px;
      }}
    >
      <TitleWrapper>
        <Title>{title}</Title>
        <Button
          variant="outline"
          size="sm"
          disabled={true}
          onClick={doGenerate}
        >
          Re-Generate
        </Button>
      </TitleWrapper>
      <CollapsibleDiv>
        <Toolbar
          onUndo={() => {
            codeMirrorRef.current.view && undo(codeMirrorRef.current.view);
          }}
          onRedo={() =>
            codeMirrorRef.current.view && redo(codeMirrorRef.current.view)
          }
        />
        {
          diff ? (
            <>
              <EditingButtonsFlex>
                Comparing new version to old version{" "}
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    updateSectionFromDiff();
                  }}
                >
                  Confirm
                </Button>{" "}
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => setDiff("")}
                >
                  Cancel
                </Button>
              </EditingButtonsFlex>
              {diffView}
            </>
          ) : (
            // <Content>{content}</Content>
            <CodeMirror
              theme={EditorTheme}
              value={content}
              ref={codeMirrorRef}
              onChange={onEditViewChange}
              onUpdate={(viewUpdate) => {
                if (viewUpdate.selectionSet) {
                  const selection = viewUpdate.state.selection;
                  if (selection) {
                    const selectedText = selection.ranges.map((range) => {
                      return content.substring(range.from, range.to);
                    });
                    // TODO: multiselection is not enabled, right?
                    setSelectedText(selectedText[0]);
                  } else {
                    setSelectedText("");
                  }
                }
              }}
              extensions={[
                EditorView.editable.of(true),
                EditorView.lineWrapping,
                // history(),
                highlight_extension,
              ]}
            ></CodeMirror>
          )
          // <MDXEditor markdown={content} />
        }
      </CollapsibleDiv>
      {selectedText && (
        <SelectionDialog
          selectedText={selectedText}
          clearSelection={clearSelection}
          userRequest={userRequest}
          setUserRequest={setUserRequest}
          onSelectionRewriteRequest={onSelectionRewriteRequest}
        />
      )}
    </Card>
  );
};

export default Section;
