// export const sendMessage = async (message: string): Promise<string> => {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(`Received: ${message}`);
//     }, 1000);
//   });
// };

var BASE_URL: string;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  BASE_URL = "https://zilinec.me/pactus-api"; //"http://localhost:8000"
} else {
  BASE_URL = "https://zilinec.me/pactus-api";
}

export const getModels = async (language: string): Promise<Response> => {
  return fetch(`${BASE_URL}/available-models?language=${language}`);
};

export const doLogin = async (
  username: string,
  hashedPassword: string,
): Promise<Response> => {
  const formData = new FormData();
  formData.set("username", username);
  formData.set("password", hashedPassword);
  const options = {
    method: "POST",
    body: formData,
  };
  return fetch(`${BASE_URL}/auth/login`, options);
};

export const getContractList = async (token: string): Promise<Response> => {
  return fetch(`${BASE_URL}/contracts/list?token=${token}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const getContract = async (id: number): Promise<Response> => {
  return fetch(`${BASE_URL}/contracts/get?id=${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export interface UpdateSection {
  id: any;
  name: string;
  content: string;
}

export const getDownloadUrl = (id: number) => {
  return `${BASE_URL}/contracts/export?id=${id}`;
};

export const updateContract = async (
  id: number,
  wholeText: string,
  sections: UpdateSection[],
) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id, wholeText, sections }),
  };
  return fetch(`${BASE_URL}/contracts/update`, options);
};

export const createContract = async (
  name: string,
  language: string,
  token: string,
): Promise<Response> => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: name,
      language: language,
      token: token,
    }),
  };
  return fetch(`${BASE_URL}/contracts/new`, options);
};

export const deleteContract = async (id: any) => {
  const options = {
    method: "DELETE",
  };
  return fetch(`${BASE_URL}/contracts/delete/${id}`, options);
};

export const finetunePrompt = async (
  id: number,
  title: string,
  prompt: string,
  model: string | null,
  variants: string | number,
): Promise<Response> => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: id,
      title: title,
      prompt: prompt,
      model: model,
      variants: variants,
    }),
  };
  return fetch(`${BASE_URL}/contracts/finetune-prompt`, options);
};

export const directlyGenerateContract = async (
    id: number,
    title: string,
    prompt: string,
    model: string | null,
    variants: string | number,
): Promise<Response> => {
  // actually this is the same as 'finetunePrompt'
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: id,
      title: title,
      prompt: prompt,
      model: model,
      variants: variants,
    }),
  };
  return fetch(`${BASE_URL}/contracts/init`, options);
};

export const generateOutline = async (
  id: number,
  prompt: string,
): Promise<Response> => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: id, prompt: prompt }),
  };

  return fetch(`${BASE_URL}/sections/generate`, options);
};

export const setSectionNames = async (
  id: number,
  sectionNames: string[],
): Promise<Response> => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: id, sections: sectionNames }),
  };
  return fetch(`${BASE_URL}/sections/set`, options);
};

export const regenerateDocument = async (
  id: number,
  byParts: boolean,
): Promise<Response> => {
  return fetch(`${BASE_URL}/content/regenerate?id=${id}&by_parts=${byParts}`);
};

export const sendCreateSection = async (
  docId: any,
  message: string,
  index: number,
): Promise<Response> => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ text: message }),
  };

  return fetch(`${BASE_URL}/create-section/${docId}?index=${index}`, options);
};

export const sendCreateSections = async (
  message: string,
): Promise<Response> => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ text: message }),
  };

  return fetch(`${BASE_URL}/create-sections/123`, options);
};

export const sendRefineSection = async (
  section_index: number,
  section_content: string,
  selected_span: string,
  prompt: string,
): Promise<Response> => {
  const body = JSON.stringify({
    section_index,
    section_content,
    selected_span,
    prompt,
  });
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  };
  return fetch(`${BASE_URL}/refine-section`, options);
};
