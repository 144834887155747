import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
  }
  #root {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
  }
  .prose {
    font-family: Times New Roman, serif;
  }
`;
